.body {
    color: #696969 !important;
}

h1, h2, h3, h4, h5, h6 {
    color: #333;
}

/* ################# Navigation ################# */

.navbar {
    background-attachment: scroll;
    -webkit-box-shadow: 0 10px 15px rgb(0 0 0 / 5%);
    -moz-box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
    box-shadow: 0 10px 15px rgb(0 0 0 / 5%);
}

.nav-item {
    margin-right: 20px;
}

.nav-link.nav-text {
    color: #333;
    opacity: 90%;
}

.navbar-toggler:focus {
    border: 0;
}

.nav-link.nav-text:focus {
    color: #333;
    opacity: 70%;
}

.nav-link.nav-text:hover {
    color: #333;
    opacity: 70%;
}

.active {
    color: #333 !important;
    opacity: 70%;
    text-decoration: underline;
    text-underline-offset: 5px;
}

/* ################# Mouse Scroll ################# */
::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

::-webkit-scrollbar-thumb {
    background: rgba(90, 90, 90);
}

::-webkit-scrollbar-track {
    background: rgba(0, 0, 0, 0.1);
}

/* ################# button ################# */

.btn {
    padding: 0 30px;
    height: 48px;
    line-height: 48px;
    font-size: 13px;
    border-radius: 5px;
}


/* ################# Footer ################# */

/* ################# Home Page ################# */
