button:hover {
    animation: scale .4s forwards;
}

@keyframes scale {
    0% {
        scale: 1;
        color: white;
        opacity: .9;
    }
    100% {
        scale: 1.07;
        color: white;
        opacity: .9;
    }
}

.scale-1:hover {
    animation: scale .1s forwards;
}

@keyframes scale {
    0% {
        scale: 1;
    }
    100% {
        scale: 1.07;
    }
}

/*#homeIntro {*/
/*    animation: float-from-bottom 300ms forwards;*/
/*}*/

.slide-up {
    animation: float-from-bottom 300ms forwards;
}


@keyframes float-from-bottom {
    0% {
        transform: translateY(200px);
        opacity: 0;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}


