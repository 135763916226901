@import url('https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600;700&display=swap');

$blue: #004edc;
$indigo: #EEF1FF;
$purple: #583D72;
$pink: #d63384;
$red: #dc3545;
$orange: #fd7e14;
$yellow: #FFBA5A;
$green: #0C9463;
$teal: #20c997;
$cyan: #ACDCEE;

$primary: $blue;
$secondary: #333;
$success: $green;
$info: $indigo;
$warning: $yellow;
$danger: $red;

$font-family-sans-serif: "Inter", Helvetica, "Helvetica Neue", Arial, sans-serif;

$navbar-toggler-focus-width: 1px;

@import "../../../node_modules/bootstrap/scss/bootstrap";